// @ts-nocheck
import React, { useState, useEffect } from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import Loadable from "react-loadable";
import reduxThunk from "redux-thunk";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import intl from "react-intl-universal";
import { getUrlParams } from "./utils";
import { appLocales, antdLocales } from "@/config/locales";
import emit from "@/utils/emit";
import reducer from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(reduxThunk))
);

const loadableProps = {
  timeout: 60 * 1000,
};
const Loading = () => null;

const Index = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Index" */ "@/pages/Index/Index"),
  loading: Loading,
});

const Box = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Box" */ "@/pages/Box/Box"),
  loading: Loading,
});

const BoxClassification = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "BoxClassification" */ "@/pages/Box/BoxClassification"
    ),
  loading: Loading,
});

const Knapsack = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Knapsack" */ "@/pages/Knapsack/Knapsack"),
  loading: Loading,
});

const Cart = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Cart" */ "@/pages/Cart/Cart"),
  loading: Loading,
});

const Mine = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Mine" */ "@/pages/Mine/Mine"),
  loading: Loading,
});

const SignIn = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "SignIn" */ "@/pages/SignIn/SignIn"),
  loading: Loading,
});

const BoxDetail = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "BoxDetail" */ "@/pages/Box/BoxDetail"),
  loading: Loading,
});

const Notice = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Notice" */ "@/pages/Notice/Notice"),
  loading: Loading,
});

const ProductDetail = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "ProductDetail" */ "@/pages/Product/ProductDetail"
    ),
  loading: Loading,
});

const ProductClassification = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "ProductClassification" */ "@/pages/Product/ProductClassification"
    ),
  loading: Loading,
});

const Gifts = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Gifts" */ "@/pages/Knapsack/Gifts"),
  loading: Loading,
});

const NoticeList = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "NoticeList" */ "@/pages/Index/NoticeList"),
  loading: Loading,
});

const PayCallBack = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "PayCallBack" */ "@/pages/PayCallBack/PayCallBack"
    ),
  loading: Loading,
});

function App() {
  const [antdLang, setAntdLang] = useState<any>(antdLocales["tr-TR"]);
  const [initLocaleDone, setInitLocaleDone] = useState<boolean>(false);

  const loadLocales = (lang = "tr-TR") => {
    intl
      .init({
        currentLocale: lang,
        locales: appLocales,
      })
      .then(() => {
        setAntdLang(antdLocales[lang]);
        localStorage.setItem("language", lang);
        setInitLocaleDone(true);
      });
  };

  useEffect(() => {
    if (window.innerWidth < 600) {
      if (getUrlParams("code")) {
        const code = getUrlParams("code");
        window.location.href = `https://h5.wkutu.com/login?code=${code}`;
      } else {
        window.location.href = "https://h5.wkutu.com";
      }
    }
  }, []);

  useEffect(() => {
    emit.on("change_language", (lang: string) => loadLocales(lang));
    loadLocales(localStorage.getItem("language") || "tr-TR");
  }, []);

  return (
    initLocaleDone && (
      <ConfigProvider locale={antdLang}>
        <Router>
          <Provider store={store}>
            <Switch>
              <Route exact path="/" render={() => <Index />} />
              <Route exact path="/box" render={() => <Box />} />
              <Route exact path="/knapsack" render={() => <Knapsack />} />
              <Route exact path="/cart" render={() => <Cart />} />
              <Route exact path="/mine/:type?" render={() => <Mine />} />
              <Route exact path="/sign-in" render={() => <SignIn />} />
              <Route
                exact
                path="/knapsack/gifts/:ids"
                render={() => <Gifts />}
              />

              <Route
                exact
                path="/box/detail/:id"
                render={() => <BoxDetail />}
              />
              <Route
                exact
                path="/product/detail/:id/:type"
                render={() => <ProductDetail />}
              />
              <Route
                exact
                path="/product/classification/:type/:id?"
                render={() => <ProductClassification />}
              />
              <Route
                exact
                path="/box/classification/:type/:id?"
                render={() => <BoxClassification />}
              />
              <Route exact path="/notice-list" render={() => <NoticeList />} />
              <Route exact path="/notice/:type/:id" render={() => <Notice />} />
              <Route
                exact
                path="/payCallBack/:id?"
                render={() => <PayCallBack />}
              />
            </Switch>
          </Provider>
        </Router>
      </ConfigProvider>
    )
  );
}

export default App;

export default (name: string) => {
  const params = window.location.href.split("?")[1];
  const value = `&${params}`;
  const parts = value.split(`&${name}=`);

  if (parts.length === 2) {
    return parts.pop().split("&").shift();
  }
  return "";
};
